<template>
  <div>
    <div v-if="apps && apps.content" class="max-w-1432 page-content-wrap px-4 mx-auto">
      <div class="content-text" v-html="apps.content"></div>
    </div>

    <WebsiteBlocks v-if="apps && apps.blocks" :blocks="apps.blocks" />
  </div>
</template>

<script>
import WebsiteBlocks from "../../../components/blocks/WebsiteBlocks";
import Multiselect from '@vueform/multiselect';

export default {
  components: {WebsiteBlocks, Multiselect},
  data() {
    return {
      apps: null,
    }
  },
  methods: {
    async fetchContent(name) {
      try {
        const { data } = await this.axios.get(`/content/${name}`);

        if (data) {
            this.apps = data.data;
        }
      } catch (ex) {
        console.log('cant fetch content: ', ex);
      }
    },
  },
    async created() {
        await this.fetchContent('apps');
    },
    mounted() {
    },
}
</script>
<style scoped lang='scss'>
.tabs {
  margin: 60px auto 40px;
}

.tabs-multi {
  display: none;
}

.tab {
  &:hover,
  &:focus {
    @apply bg-darker-200 text-white text-opacity-75;
  }

  &:hover,
  &:focus,
  &.active {
    @apply bg-darker-200 text-white;
  }

  &.active {
    pointer-events: none;
  }
}

@media all and (max-width: 860px) {
  .tabs {
    display: none;
  }

  .tabs-multi {
    display: block;
    margin: 44px auto 24px;
  }

  .custom-multiselect {
    max-width: 360px;
    color: #fff;
    border-color: #202020;
    font-family: "Gilroy", sans-serif;
    font-size: 18px;
    line-height: 22px;

    &::v-deep {
      .multiselect-wrapper {
        @apply bg-darker-200;
      }

      .multiselect-search::placeholder,
      .multiselect-search {
        @apply bg-darker-200 text-white;
      }

      .multiselect-caret,
      .multiselect-clear-icon {
        background-color: #fff;
      }

      .multiselect-option.is-selected {
        background-color: #E70020;
      }

      .multiselect-dropdown {
        background-color: #202020;
        color: #fff;
      }

      .multiselect-option {
        border-bottom: 1px solid #fff;

        &:hover,
        &:focus {
          cursor: pointer;
          transition: all .3s ease;
          background-color: rgba(#E70020, 0.9);
          color: #fff;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>
